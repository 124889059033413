export var createEventEmitter = function createEventEmitter() {
  var allHandlers = {};
  var lastValues = {};
  return {
    on: function on(type, handler) {
      var handlers = allHandlers[type] || (allHandlers[type] = []);
      handlers.push(handler);
      return function () {
        var index = handlers.indexOf(handler);

        if (index > -1) {
          handlers.splice(index, 1);
        }
      };
    },
    emit: function emit(type, data) {
      if (allHandlers[type]) {
        allHandlers[type].forEach(function (handler) {
          return handler(data);
        });
      }

      lastValues[type] = data;
    },
    lastValue: function lastValue(type) {
      return lastValues[type];
    }
  };
};