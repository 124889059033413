// https://thatemil.com/blog/2015/01/03/reset-your-fieldset/
export var fieldsetReset = function fieldsetReset() {
  return {
    border: 0,
    padding: '0.01em 0 0 0',
    margin: 0,
    minWidth: 0
  };
}; // https://thatemil.com/blog/2015/01/03/reset-your-fieldset/

export var legendReset = function legendReset() {
  return {
    padding: 0,
    display: 'table'
  };
};